import { Pipe, PipeTransform } from '@angular/core';
import { get } from 'lodash';
import { CityLocation, CountryLocation } from '../providers/types/ycommercewebservices';
import { TreeItem } from '../shared/components/tree/tree-model';

@Pipe({
  name: 'countryLocationsToTreeItems'
})
export class CountryLocationsToTreeItemsPipe implements PipeTransform {
  transform(locations: CountryLocation[], selectedLocations?: CountryLocation[]): TreeItem[] {
    return (locations || []).map((country) => {
      const countryIsoCode = get(country, 'isoCode', '');
      const cities = get(country, 'cities', []) || [];
      return {
        id: countryIsoCode,
        label: get(country, 'countryName', ''),
        selected: this.checkSelection(selectedLocations || [], cities.length, countryIsoCode),
        children: this.toTreeItems(selectedLocations || [], countryIsoCode, cities),
        hideChildren: false,
      }  as TreeItem;
    });
  }

  private toTreeItems(selectedLocations: CountryLocation[], country: string, cities?: CityLocation[]): TreeItem[] {
    return (cities || []).map(city => {
      const companies = (get(city, 'companies', []) || []) || [];
      return {
        label: get(city, 'city', ''),
        selected: this.checkSelection(selectedLocations, companies.length, country, get(city, 'city', '')),
        hideChildren: companies.length <= 1,
        children: companies.map(company => ({
          label: company,
          selected: this.checkSelection(selectedLocations, 0, country, get(city, 'city', ''), company),
        })),
      };
    });
  }

  private checkSelection(selectedLocations: CountryLocation[], numberOfChildren: number, country?: string, city?: string, company?: string): boolean {
    if (company) {
      return !!this.takeCompanyByName(selectedLocations, country, city, company);
    } else if (city) {
      const cityLocation = this.takeCityByIsoCode(selectedLocations, country, city);
      return !!cityLocation && numberOfChildren === get(cityLocation, 'companies.length', 0);
    } else if (country) {
      const countryLocation = this.takeCountryByIsoCode(selectedLocations, country);
      return !!countryLocation && numberOfChildren === get(countryLocation, 'cities.length', 0);
    }

    return false;
  }

  private takeCountryByIsoCode(selectedLocations: CountryLocation[], country?: string): CountryLocation {
    return country ? (selectedLocations || []).find(l => l && l.isoCode === country) : null;
  }

  private takeCityByIsoCode(selectedLocations: CountryLocation[], country?: string, city?: string): CityLocation | null {
    const currentCountry = this.takeCountryByIsoCode(selectedLocations, country);

    if (currentCountry && city) {
      return (currentCountry.cities || []).find(c => c && c.city === city);
    }

    return null;
  }

  private takeCompanyByName(selectedLocations: CountryLocation[], country?: string, city?: string, company?: string): string | null {
    const currentCity = this.takeCityByIsoCode(selectedLocations, country, city);

    if (currentCity && company) {
      return (currentCity.companies || []).find(c => c === company);
    }

    return null;
  }
}
