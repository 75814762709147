import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthorizationService } from '../../providers/plansee/authorization-service';
import { PlanseeTranslateService } from '../../providers/plansee/p-translate-service';
import {
  INVOICE_CATEGORY_FACET_CODE,
  InvoiceBaseQuery,
  InvoiceCategoryCode,
} from '../../providers/types/invoices/invoice-base-query';
import { SearchTab } from '../../shared/components/search-tabs/search-tab';
import { InvoiceType } from '../../providers/types/invoices/invoice-type.enum';
import { PInvoiceWsDTO, PSearchPageWsDTO } from '../../providers/types/planseeoccaddon';
import { defaultTo } from 'lodash';
import { PTabsPage } from '../p-tabs-page';
import { DownloadEvent, DownloadType } from '../../providers/types/download';
import { AppModeService } from '../../providers/plansee/app-mode.service';
import { AppMode } from '../../providers/types/app-mode';
import { DownloadService } from '../../providers/plansee/download.service';

const Invoices: SearchTab<InvoiceType, InvoiceBaseQuery>[] = [
  {
    type: InvoiceType.INVOICE,
    count: 0,
    facetCode: INVOICE_CATEGORY_FACET_CODE,
    code: 'invoice',
    query: InvoiceBaseQuery.INVOICE,
    translationKey: 'invoicesTabs.invoices.pl'
  }, {
    type: InvoiceType.CREDIT_DEBIT_NOTES,
    count: 0,
    facetCode: INVOICE_CATEGORY_FACET_CODE,
    code: 'credit_debit_notes',
    query: InvoiceBaseQuery.CREDIT_DEBIT_NOTES,
    translationKey: 'invoicesTabs.creditDebitNotes.pl'
  }, {
    type: InvoiceType.ADVANCED_PAYMENTS,
    count: 0,
    facetCode: INVOICE_CATEGORY_FACET_CODE,
    code: 'advanced_payments',
    query: InvoiceBaseQuery.ADVANCED_PAYMENTS,
    translationKey: 'invoicesTabs.advancedPayments.pl'
  }];

@Component({
  selector: 'invoices-page',
  template: require('./invoices.page.html'),
  styles: []
})
export class InvoicesPageComponent extends PTabsPage<InvoiceType, InvoiceBaseQuery> {

  currentMode$ = this.appModeService.currentMode$;

  tabs = Invoices;
  selectedTab = this.tabs[0];
  selectedTabQuery = InvoiceBaseQuery;
  selectedInvoices: PInvoiceWsDTO[] = [];
  downloadEvent: DownloadEvent;

  AppMode = AppMode;

  constructor(
    protected route: ActivatedRoute,
    protected authorizationService: AuthorizationService,
    protected planseeTranslateService: PlanseeTranslateService,
    private downloadService: DownloadService,
    private appModeService: AppModeService
  ) {
    super(route, authorizationService, planseeTranslateService);
  }

  onSearchResult(result: PSearchPageWsDTO) {
    this.searchResult = result;
    const tempResult = defaultTo(result, {facets: []});
    const queryContainsFacetCode = defaultTo(result.currentQuery.query.value, '').indexOf(`:${INVOICE_CATEGORY_FACET_CODE}:`) !== -1;

    if (tempResult.facets.length && !queryContainsFacetCode) {
      const facet = tempResult.facets.find(f => f.code === INVOICE_CATEGORY_FACET_CODE);
      const areValuesForFacet = defaultTo(facet, {values: []}).values.length;
      const facetInvoice = areValuesForFacet ? facet.values.find(f => f.code === InvoiceCategoryCode.INVOICE) : null;
      const facetCreditDebitNotes = areValuesForFacet ? facet.values.find(f => f.code === InvoiceCategoryCode.CREDIT_DEBIT_NOTES) : null;
      const facetAdvancedPayments = areValuesForFacet ? facet.values.find(f => f.code === InvoiceCategoryCode.ADVANCED_PAYMENTS) : null;

      this.tabs[0].count = defaultTo(facetInvoice, {count: 0}).count;
      this.tabs[1].count = defaultTo(facetCreditDebitNotes, {count: 0}).count;
      this.tabs[2].count = defaultTo(facetAdvancedPayments, {count: 0}).count;
      this.setDefaultTab();
    } else if (!queryContainsFacetCode) {
      this.tabs[0].count = 0;
      this.tabs[1].count = 0;
      this.tabs[2].count = 0;
      this.setDefaultTab();
    }
  }

  onDownload(type: DownloadType) {
    if (type === DownloadType.SELECTED_AS_PDF) {
      this.onDownloadSelectedPdfClick();
    } else if (type === DownloadType.SELECTED_AS_XLS || type === DownloadType.ENTIRE_AS_XLS) {
      this.downloadEvent = {
        downloadType: null,
        selectedCodes: []
      };
      setTimeout(() => {
        this.downloadEvent = {
          downloadType: type,
          selectedCodes: defaultTo(this.selectedInvoices, [])
            .map(item => defaultTo(item.invoiceNumber, '').toString())
        };
      });
    }
  }

  private onDownloadSelectedPdfClick() {
    this.downloadService.downloadMultipleDocuments(
      defaultTo(this.selectedInvoices, []).map(item => defaultTo(item.documentId, '').toString())
    );
  }
}
