import { Injectable } from '@angular/core';
import { PSelectValue } from '../../form-components/p-select/p-select-value';
import { CountryLocation, UserLocation } from './ycommercewebservices';

export const ALL_LOCATION_VALUE = 'all';

@Injectable()
export class GlobalsService {

  readonly DEFAULT_TITLES = [
    { name: 'general.titles.mr', code: '0002' },
    { name: 'general.titles.ms', code: '0001' }
  ];

  readonly DEFAULT_POSITIONS = [
    { key: 'general.positions.purchasing', value: 'Purchasing' },
    { key: 'general.positions.sales', value: 'Sales' },
    { key: 'general.positions.accounting', value: 'Accounting' },
    { key: 'general.positions.qualityManagement', value: 'Quality Management' },
    { key: 'general.positions.researchAndDevelopment', value: 'Research and Development' },
    { key: 'general.positions.production', value: 'Production' },
    { key: 'general.positions.engineer', value: 'Engineer' },
    { key: 'general.positions.other', value: 'Other' }
  ];

  readonly DEFAULT_COUNTRIES = [
    { name: 'general.countries.germany', isocode: 'DE' },
    { name: 'general.countries.us', isocode: 'US' },
    { name: 'general.countries.canada', isocode: 'CA' },
    { name: 'general.countries.china', isocode: 'CN' },
    { name: 'general.countries.italy', isocode: 'IT' },
    { name: 'general.countries.austria', isocode: 'AT' },
    { name: 'general.countries.greatBritain', isocode: 'GB' },
    { name: 'general.countries.croatia', isocode: 'HR' },
    { name: 'delimiter', isocode: null, disabled: true },
  ];

  readonly DEFAULT_PHONE_COUNTRY_CODES = [
    {isocode: 'AL', name: 'Albania', countrycode: '355'},
    {isocode: 'AD', name: 'Andorra', countrycode: '376'},
    {isocode: 'AT', name: 'Austria', countrycode: '43'},
    {isocode: 'BY', name: 'Belarus', countrycode: '375'},
    {isocode: 'BE', name: 'Belgium', countrycode: '32'},
    {isocode: 'BA', name: 'Bosnia and Herzegovina', countrycode: '387'},
    {isocode: 'BG', name: 'Bulgaria', countrycode: '359'},
    {isocode: 'CA', name: 'Canada', countrycode: '1'},
    {isocode: 'CN', name: 'China', countrycode: '86'},
    {isocode: 'HR', name: 'Croatia', countrycode: '385'},
    {isocode: 'CY', name: 'Cyprus', countrycode: '357'},
    {isocode: 'CZ', name: 'Czech Republic', countrycode: '420'},
    {isocode: 'DK', name: 'Denmark', countrycode: '45'},
    {isocode: 'EE', name: 'Estonia', countrycode: '372'},
    {isocode: 'FO', name: 'Faroe Islands', countrycode: '298'},
    {isocode: 'FI', name: 'Finland', countrycode: '358'},
    {isocode: 'FR', name: 'France', countrycode: '33'},
    {isocode: 'DE', name: 'Germany', countrycode: '49'},
    {isocode: 'GI', name: 'Gibraltar', countrycode: '350'},
    {isocode: 'GR', name: 'Greece', countrycode: '30'},
    {isocode: 'GL', name: 'Greenland', countrycode: '299'},
    {isocode: 'GG', name: 'Guernsey', countrycode: '44-1481'},
    {isocode: 'VA', name: 'Holy See (Vatican City State)', countrycode: '379'},
    {isocode: 'HK', name: 'Hong Kong', countrycode: '852'},
    {isocode: 'HU', name: 'Hungary', countrycode: '36'},
    {isocode: 'IS', name: 'Iceland', countrycode: '354'},
    {isocode: 'IE', name: 'Ireland', countrycode: '353'},
    {isocode: 'IM', name: 'Isle of Man', countrycode: '44-1624'},
    {isocode: 'IT', name: 'Italy', countrycode: '39'},
    {isocode: 'JP', name: 'Japan', countrycode: '81'},
    {isocode: 'JE', name: 'Jersey', countrycode: '44-1534'},
    {isocode: 'KP', name: 'Korea, Democratic People\'s Republic of', countrycode: '850'},
    {isocode: 'KR', name: 'Korea, Republic of', countrycode: '82'},
    {isocode: 'LV', name: 'Latvia', countrycode: '371'},
    {isocode: 'LI', name: 'Liechtenstein', countrycode: '423'},
    {isocode: 'LT', name: 'Lithuania', countrycode: '370'},
    {isocode: 'LU', name: 'Luxembourg', countrycode: '352'},
    {isocode: 'MK', name: 'Macedonia', countrycode: '389'},
    {isocode: 'MT', name: 'Malta', countrycode: '356'},
    {isocode: 'MD', name: 'Moldova', countrycode: '373'},
    {isocode: 'MC', name: 'Monaco', countrycode: '377'},
    {isocode: 'ME', name: 'Montenegro', countrycode: '382'},
    {isocode: 'NL', name: 'Netherlands', countrycode: '31'},
    {isocode: 'NO', name: 'Norway', countrycode: '47'},
    {isocode: 'PL', name: 'Poland', countrycode: '48'},
    {isocode: 'PT', name: 'Portugal', countrycode: '351'},
    {isocode: 'RO', name: 'Romania', countrycode: '40'},
    {isocode: 'RU', name: 'Russian Federation', countrycode: '7'},
    {isocode: 'SM', name: 'San Marino', countrycode: '378'},
    {isocode: 'RS', name: 'Serbia', countrycode: '381'},
    {isocode: 'SK', name: 'Slovakia', countrycode: '421'},
    {isocode: 'SI', name: 'Slovenia', countrycode: '386'},
    {isocode: 'ES', name: 'Spain', countrycode: '34'},
    {isocode: 'SE', name: 'Sweden', countrycode: '46'},
    {isocode: 'CH', name: 'Switzerland', countrycode: '41'},
    {isocode: 'TR', name: 'Turkey', countrycode: '90'},
    {isocode: 'UA', name: 'Ukraine', countrycode: '380'},
    {isocode: 'GB', name: 'United Kingdom', countrycode: '44'},
    {isocode: 'US', name: 'United States', countrycode: '1'},
    {isocode: 'VN', name: 'Viet Nam', countrycode: '84'}
  ];

  readonly DEFAULT_USERTYPE = {
    REGULAR: 'REGULAR',
    SUPERUSER: 'SUPERUSER',
    SUPERUSER_MASTER: 'SUPERUSER_MASTER'
  };

  readonly DEFAULT_USERTYPES = [
    { value: 'SUPERUSER_MASTER', name: 'general.userTypes.superAdmin', short: 'SUM' },
    { value: 'SUPERUSER', name: 'general.userTypes.administrator', short: 'SU' },
    { value: 'REGULAR', name: 'general.userTypes.normalUser', short: 'User' },
  ];

  // SAP system => Password Rules
  readonly PW_RULES = {
    min_lng: 8,
    max_lng: 18,
    letters: {
      lowercase: 'a-zäöüß',
      uppercase: 'A-ZÄÖÜ'
    },
    digits: '0-9',
    specials: '!@#$%^&;*)(+=._-?§[]{}<>°"²³`´€~\'|µ,;:\\' // escaped characters: '\
  };

  public getTitleNames(): any {
    return this.DEFAULT_TITLES.map(val => val.name);
  }

  public getTitleCodes(): any {
    return this.DEFAULT_TITLES.map(val => val.code);
  }

  public getTitles(): PSelectValue[] {
    return this.DEFAULT_TITLES.map(val => ({value: val.code, label: val.name}));
  }

  public getJobPositions(): PSelectValue[] {
    return this.DEFAULT_POSITIONS.map(pos => ({value: pos.value, label: pos.key}));
  }

  public getCountries(): PSelectValue[] {
    return this.DEFAULT_COUNTRIES.map(val => ({value: val.isocode, label: val.name, disabled: val.disabled}));
  }

  public getLocationsWithoutWildcards(locations: CountryLocation[]): CountryLocation[] {
    if (locations) {
      // exclude wildcards
      return locations.filter(location => location && location.isoCode && location.isoCode !== ALL_LOCATION_VALUE);
    }
    return [];
  }

  // TODO it should be removed in task MYPLANSEE-1022
  public toUserLocationList(locations: CountryLocation[]): UserLocation[] {
    const userLocations: UserLocation[] = [];

    if (locations) {
      locations.forEach(location => {
        if (location && location.isoCode && location.countryName && location.cities && location.cities.length) {
          location.cities.forEach(city => {
            if (city && city.city) {
              userLocations.push({
                isoCode: location.isoCode,
                countryName: location.countryName,
                city: city.city,
                fullName: `${location.countryName}, ${city.city}`,
              });
            }
          });
        }
      });
    }

    return userLocations;
  }

  public getUserTypes(): any {
    return {
      values: this.DEFAULT_USERTYPES.map(userType => userType.value),
      names: this.DEFAULT_USERTYPES.map(userType => userType.name)
    };
  }

  public getUserTypesExcludingSUM(): PSelectValue[] {
    return this.DEFAULT_USERTYPES.filter(userType => userType.value !== 'SUPERUSER_MASTER')
      .map(type => ({value: type.value, label: type.name}));
  }

  public getRegExpForPW(): RegExp {
    // /pattern/
    const pattern = `^`
      + `(?=.*[${this.PW_RULES.letters.lowercase}])`
      + `(?=.*[${this.PW_RULES.letters.uppercase}])`
      + `(?=.*[${this.PW_RULES.digits}])`
      + `(?=.*[${this.escapeRegExp(this.PW_RULES.specials)}])`
      + `.{${this.PW_RULES.min_lng},${(this.PW_RULES.max_lng && this.PW_RULES.max_lng > 0) ? this.PW_RULES.max_lng : ''}}`
      + `$`;
    return new RegExp(pattern);
  }

  private escapeRegExp(string: string) {
    // @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions => Escaping
    return string.replace(/[.*+\-?^$\'{}()|[\]\\]/g, '\\$&');
  }
}
