import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {NgbDropdownModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TranslateModule} from '@ngx-translate/core';
import {DataTableModule} from 'ngx-datatable-bootstrap4';
import {OAuthModule} from 'ngx-oauth';
import {RangeSliderModule} from 'ngx-range-slider';
import {AppRoutingModule} from '../app.routing';
import {FiltersModule} from '../filters/filters.module';
import {CustomerSelectionComponent} from './customer-selection/customer-selection.component';
import {FacetAppliedComponent} from './facet-applied/facet-applied.component';
import {FacetDateComponent} from './facet-date/facet-date.component';
import {FacetDimensionsComponent} from './facet-dimensions/facet-dimensions.component';
import {FacetSummaryComponent} from './facet-summary/facet-summary.component';
import {InterModuleLinksComponent} from './inter-module-links/inter-module-links.component';
import {OrdersListComponent} from './orders-list/orders-list.component';
import {OrdersSearchComponent} from './orders-search/orders-search.component';
import {ProductClassificationsComponent} from './product-classifications/product-classifications.component';
import {ProductInfoComponent} from './product-info/product-info.component';
import {InvoicesListComponent} from './invoices-list/invoices-list.component';
import {InvoicesSearchComponent} from './invoices-search/invoices-search.component';
import {ShipmentsListComponent} from './shipments-list/shipments-list.component';
import {ShipmentsSearchComponent} from './shipments-search/shipments-search.component';
import {RebirthChartModule} from 'rebirth-chart';
import {DocumentsListComponent} from './documents-list/documents-list.component';
import {DocumentsSearchComponent} from './documents-search/documents-search.component';
import {DocumentsNavigationComponent} from './documents-navigation/documents-navigation.component';
import {ConsignmentsListComponent} from './consignments-list/consignments-list.component';
import {ConsignmentsSearchComponent} from './consignments-search/consignments-search.component';
import {ProductVolumeChartComponent} from './product-volume-chart/product-volume-chart.component';
import {ForecastVolumeChartComponent} from './forecast-volume-chart/forecast-volume-chart.component';
import {FrameContractComponent} from './frame-contract/frame-contract.component';
import {StockLevelsComponent} from './stock-levels/stock-levels.component';
import {StockVolumeChartComponent} from './stock-volume-chart/stock-volume-chart.component';
import {ClaimsSearchComponent} from './claims-search/claims-search.component';
import {ClaimsListComponent} from './claims-list/claims-list.component';
import {ForecastsListComponent} from './forecasts-list/forecasts-list.component';
import {ForecastsSearchComponent} from './forecasts-search/forecasts-search.component';
import {FacetDefaultComponent} from './facet-default/facet-default.component';
import {HeaderPanelComponent} from './header-panel/header-panel.component';
import {MenuPanelComponent} from './menu-panel/menu-panel.component';
import {ProfileDropdownComponent} from './profile-dropdown/profile-dropdown.component';
import {LanguageSelectorComponent} from './language-selector/language-selector.component';
import {ProductsListComponent} from './products-list/products-list.component';
import {ProductsSearchComponent} from './products-search/products-search.component';
import {FileDropModule} from 'ngx-file-drop';
import {UserDetailsComponent} from './user-details/user-details';
import {AnimationsModule} from '../animations/animations';
import {ClaimFormStep1Component} from './claim-form-step-1/claim-form-step-1.component';
import {ClaimFormStep2Component} from './claim-form-step-2/claim-form-step-2.component';
import {ClaimFormStep3Component} from './claim-form-step-3/claim-form-step-3.component';
import {QuotationsListComponent} from './quotations-list/quotations-list.component';
import {QuotationsSearchComponent} from './quotations-search/quotations-search.component';
import {QuotationInfoComponent} from './quotation-info/quotation-info.component';
import {UserNotificationsComponent} from './user-notifications/user-notifications.component';
import {UserProfileComponent} from './user-profile/user-profile.component';
import {FileViewComponent} from './file-view/file-view.component';
import {QuotationFormComponent} from './quotation-form/quotation-form.component';
import {QuotationEntryFormComponent} from './quotation-entry-form/quotation-entry-form.component';
import {FormComponentsModule} from '../form-components/form-components.module';
import {ImprintContentComponent} from './imprint-content/imprint-content.component';
import {FacetSliderComponent} from './facet-slider/facet-slider.component';
import {ClaimInfoComponent} from './claim-info/claim-info.component';
import {ClaimFormStep4Component} from './claim-form-step-4/claim-form-step-4.component';
import {NotificationsDropdownComponent} from './notifications-dropdown/notifications-dropdown.component';
import {ShipmentInfoInlineComponent} from './shipment-info-inline/shipment-info-inline.component';
import {InterModuleLinksInlineComponent} from './inter-module-links-inline/inter-module-links-inline.component';
import {NoResultContentComponent} from './no-result-content/no-result-content.component';
import {ChangePasswordComponent} from './change-password/change-password.component';
import {DownloadModalComponent} from './download-modal/download-modal.component';
import {UsersListComponent} from './users-list/users-list.component';
import {ConfirmationModalComponent} from './confirmation-modal/confirmation-modal.component';
import {ActionModalComponent} from './action-modal/action-modal.component';
import {UserFormComponent} from './user-form/user-form.component';
import {LocationSelectionComponent} from './location-selection/location-selection.component';
import {PlanseeDashboardModule} from './dashboard/plansee-dashboard.module';
import {PrivacyPolicyModalComponent} from './privacy-policy-modal/privacy-policy-modal.component';
import {ImprintLangModule} from './imprint-content/langs/imprint-lang.module';
import {PublicHeaderComponent} from './public-header/public-header.component';
import {GeneralConditionsOfSaleComponent} from './general-conditions-of-sale/general-conditions-of-sale.component';
import {
  GeneralConditionsOfSaleLangModule
} from './general-conditions-of-sale/langs/general-conditions-of-sale-lang.module';
import {DataProtectionLangModule} from './data-protection/langs/data-protection-lang.module';
import {DataProtectionComponent} from './data-protection/data-protection.component';
import {IFrameModalComponent} from './iframe-modal/iframe-modal.component';
import {LoginFaqsComponent} from './login-faqs/login-faqs.component';
import {FixedEmptyTableComponent} from './fixed-empty-table/fixed-empty-table.component';
import { BookmarkModule } from '../shared/components/bookmark/bookmark.module';
import { InvoicesComponent } from './invoices-v2/invoices.component';
import { PlanseeTableComponent } from './plansee-table/plansee-table.component';
import { PlanseeTableFiltersComponent } from './plansee-table/plansee-table-filters/plansee-table-filters.component';
import { PlanseeTableTabsComponent } from './plansee-table/plansee-table-tabs/plansee-table-tabs.component';
import { PlanseeTableListTemplateDirective } from './plansee-table/plansee-table-list-template.directive';
import { CalculateBreadcrumbsPipe } from './plansee-table/plansee-table-filters/calculate-breadcrumbs.pipe';
import { ShipmentsComponent } from './shipments-v2/shipments.component';
import { MakeDateFacetPipe } from './plansee-table/plansee-table-filters/make-date-facet.pipe';
import { TreeModule } from '../shared/components/tree/tree.module';
import { QuotationsComponent } from './quotations-v2/quotations.component';
import { QuotationsTableComponent } from './quotations-v2/quotations-table/quotations-table.component';
import { OrdersComponent } from './orders-v2/orders.component';
import { MakeDefaultFacetPipe } from './plansee-table/plansee-table-filters/make-default-facet.pipe';
import { TakeMainPaginationPipe } from './plansee-table/take-main-pagination.pipe';
import { TakeSelectedTabIdPipe } from './plansee-table/take-selected-tab-id.pipe';

const components = [
  HeaderPanelComponent,
  MenuPanelComponent,
  ProfileDropdownComponent,
  LanguageSelectorComponent,
  FacetDefaultComponent,
  FacetDateComponent,
  FacetAppliedComponent,
  FacetSummaryComponent,
  FacetSliderComponent,
  FacetDimensionsComponent,
  CustomerSelectionComponent,
  ProductsSearchComponent,
  ProductsListComponent,
  ProductVolumeChartComponent,
  InvoicesListComponent,
  InvoicesSearchComponent,
  ProductInfoComponent,
  ProductClassificationsComponent,
  InterModuleLinksComponent,
  OrdersListComponent,
  OrdersSearchComponent,
  ShipmentsListComponent,
  ShipmentsSearchComponent,
  ForecastsListComponent,
  ForecastsSearchComponent,
  ForecastVolumeChartComponent,
  DocumentsListComponent,
  DocumentsSearchComponent,
  DocumentsNavigationComponent,
  ConsignmentsListComponent,
  ConsignmentsSearchComponent,
  FrameContractComponent,
  StockLevelsComponent,
  StockVolumeChartComponent,
  ClaimsSearchComponent,
  ClaimsListComponent,
  ClaimInfoComponent,
  UserDetailsComponent,
  ClaimFormStep1Component,
  ClaimFormStep2Component,
  ClaimFormStep3Component,
  FileViewComponent,
  QuotationInfoComponent,
  QuotationsListComponent,
  QuotationsSearchComponent,
  UserProfileComponent,
  UserNotificationsComponent,
  QuotationFormComponent,
  QuotationEntryFormComponent,
  ClaimFormStep4Component,
  ImprintContentComponent,
  NotificationsDropdownComponent,
  ShipmentInfoInlineComponent,
  InterModuleLinksInlineComponent,
  NoResultContentComponent,
  ChangePasswordComponent,
  DownloadModalComponent,
  UsersListComponent,
  ConfirmationModalComponent,
  ActionModalComponent,
  UserFormComponent,
  LocationSelectionComponent,
  PrivacyPolicyModalComponent,
  PublicHeaderComponent,
  GeneralConditionsOfSaleComponent,
  DataProtectionComponent,
  IFrameModalComponent,
  LoginFaqsComponent,
  FixedEmptyTableComponent,

  // components for new version
  PlanseeTableComponent,
  PlanseeTableListTemplateDirective,
  PlanseeTableFiltersComponent,
  PlanseeTableTabsComponent,
  CalculateBreadcrumbsPipe,
  MakeDateFacetPipe,
  MakeDefaultFacetPipe,
  TakeMainPaginationPipe,
  TakeSelectedTabIdPipe,
  InvoicesComponent,
  ShipmentsComponent,
  QuotationsComponent,
  QuotationsTableComponent,
  OrdersComponent
];

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    TranslateModule,
    NgbModule,
    DataTableModule,
    FiltersModule,
    AnimationsModule,
    OAuthModule,
    RangeSliderModule,
    RebirthChartModule,
    FileDropModule,
    FormComponentsModule,
    PlanseeDashboardModule,
    NgbDropdownModule,
    ImprintLangModule,
    GeneralConditionsOfSaleLangModule,
    DataProtectionLangModule,
    BookmarkModule,
    TreeModule
  ],
  declarations: components,
  exports: [
    ...components,
    PlanseeDashboardModule
  ]
})
export class ComponentsModule {
}
