import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TruncateTextPipe } from './truncateText.pipe';
import { TruncateNumberPipe } from './truncateNumber.pipe';
import { FileSizePipe } from './fileSize.pipe';
import { StripHtmlPipe } from './stripHtml.pipe';
import { ApiHostPipe } from './apiHost.pipe';
import { PlanseeModule } from '../providers/plansee/plansee.module';
import { StripTimeStringPipe } from './stripTimeString.pipe';
import { SanitizeHtmlPipe, SanitizeStylePipe, SanitizeUrlPipe } from './sanitize';
import { FloorPipe } from './floor.pipe';
import { TranslateCutPipe } from './translateCut.pipe';
import { UrlEncodePipe } from './urlEncode.pipe';
import { GetConsignmentValuePipe } from './getConsignmentValue.pipe';
import { GetForecastValuePipe } from './getForecastValue.pipe';
import { IsNormalForecastPipe } from './isNormalForecast.pipe';
import { IsNormalConsignmentPipe } from './isNormalConsignment.pipe';
import { IconPipe } from './icon.pipe';
import { AtLeastTwoTabsHaveData } from './invoices/atLeastTwoTabsHaveData';
import { AddProtocolPipe } from './addProtocol.pipe';
import { LangCodeFormatter } from './langCodeFormatter';
import { CustomDatePipe } from './custom-date.pipe';
import { UrlToHybrisPipe } from './urlToHybris.pipe';
import { LangNameFormatter } from './langNameFormatter';
import { TakeLangPipe } from './take-lang-name.pipe';
import { TakeTitleNamePipe } from './takeTitleName.pipe';
import { IsCancelledPipe } from './invoices/isCancelled.pipe';
import { OrderByPipe } from './orderBy.pipe';
import { CombineMediasPipe } from './combine-medias.pipe';
import { FilterFacetsBySelectedTabKeyPipe } from './filterFacetsBySelectedTabKey.pipe';
import { FilterBreadcrumbsBySelectedTabKeyPipe } from './filterBreadcrumbsBySelectedTabKey.pipe';
import { DocumentLinkPipe } from './document-link.pipe';
import { SortModulesPipe } from './sort-modules.pipe';
import { CountryLocationsToTreeItemsPipe } from './country-locations-to-tree-items.pipe';
import { AppModeDatePipe } from './app-mode-date.pipe';

const declarations = [
  TruncateTextPipe,
  TruncateNumberPipe,
  FileSizePipe,
  StripHtmlPipe,
  ApiHostPipe,
  StripTimeStringPipe,
  SanitizeHtmlPipe,
  SanitizeStylePipe,
  SanitizeUrlPipe,
  FloorPipe,
  TranslateCutPipe,
  UrlEncodePipe,
  GetConsignmentValuePipe,
  GetForecastValuePipe,
  IsNormalForecastPipe,
  IsNormalConsignmentPipe,
  IconPipe,
  AtLeastTwoTabsHaveData,
  AddProtocolPipe,
  LangCodeFormatter,
  CustomDatePipe,
  UrlToHybrisPipe,
  LangNameFormatter,
  TakeLangPipe,
  TakeTitleNamePipe,
  IsCancelledPipe,
  OrderByPipe,
  CombineMediasPipe,
  FilterFacetsBySelectedTabKeyPipe,
  FilterBreadcrumbsBySelectedTabKeyPipe,
  DocumentLinkPipe,
  SortModulesPipe,
  CountryLocationsToTreeItemsPipe,
  AppModeDatePipe,
];

@NgModule({
  imports: [
    TranslateModule,
    PlanseeModule
  ],
  declarations: declarations,
  exports: declarations
})
export class FiltersModule {
}
