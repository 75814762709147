import { Injectable } from '@angular/core';
import { PService } from './p-service';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config-service';
import { AuthorizationService } from './authorization-service';
import { HttpResponse } from '@angular/common/http/src/response';
import { take } from 'rxjs/operators';
import { downloadBlob } from '../../../utils';
import { PDocumentsWsDTO } from '../types/planseeoccaddon';
import { Router } from '@angular/router';
import { DownloadDocumentType } from '../types/download';

@Injectable()
export class PlanseeDocumentsService extends PService {

  basePath = 'planseecommercewebservices/v2/plansee';

  constructor(http: HttpClient,
              configService: ConfigService,
              authorizationService: AuthorizationService,
              private router: Router, ) {
    super(http, authorizationService);
    configService.onConfigSet.subscribe(value => {
      if (value && configService.config.apiHost) {
        const apiHost = configService.config.apiHost;
        this.basePath = `${apiHost}/${this.basePath}`;
      }
    });
  }

  getDocumentLink(itemNumber: string, documentIdOrDunningNoteId: string, documentType: DownloadDocumentType): string {
    return this.createUrl(itemNumber, documentIdOrDunningNoteId, documentType).toJSON();
  }

  takeFileAsBlob(itemNumber: string, documentIdOrDunningNoteId: string, documentType: DownloadDocumentType, mode: 'OPEN' | 'DOWNLOAD' = 'OPEN') {
    const params = {};
    this.createUrl(itemNumber, documentIdOrDunningNoteId, documentType).searchParams.forEach((value, key) => {
      if (key && value) {
        params[key] = value;
      }
    });

    this.get<HttpResponse<Blob>>('documents/get', {
      params: params,
      headers: {
        'Content-Type': 'application/json',
      },
      observe: 'response',
      responseType: 'blob' as 'json',
    })
      .pipe(take(1))
      .subscribe(value => {
        if (value.body) {
          if (mode === 'DOWNLOAD') {
            downloadBlob(value.body, value.headers ? value.headers.get('custom-filename') : '');
          } else {
           const tempUrl = window.URL.createObjectURL(value.body);
           window.open(tempUrl, '_blank');
          }
        }
      });
  }

  checkLinkAndHandleDownload(document: PDocumentsWsDTO, isPreview: boolean, type: DownloadDocumentType) {
    const downloadLink = (document && document.id) ? `documents/${this.urlEncode.transform(document.id)}` : null;
    if (!downloadLink) {
      this.router.navigate(['/error'], { skipLocationChange: false });
      return;
    }

    return this.takeFileAsBlob(document.documentNumber, document.id, type, isPreview ? 'OPEN' : 'DOWNLOAD');
  }

  private createUrl(itemNumber: string, documentIdOrDunningNoteId: string, documentType: DownloadDocumentType): URL {
    const companyId = this.authorizationService.companyId || '';
    const url = new URL(`${this.getEndpoint()}/documents/get`);
    url.searchParams.set('documentNumber', itemNumber || '');
    url.searchParams.set('attachmentId', documentIdOrDunningNoteId || '');
    url.searchParams.set('companyId', companyId);
    url.searchParams.set('documentType', documentType);
    return url;
  }
}
