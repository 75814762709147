import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { PDataTable } from '../p-data-table';
import { LoadingService } from '../../providers/plansee/loading-service';
import { TranslateService } from '@ngx-translate/core';
import { DataTableParams } from 'ngx-datatable-bootstrap4';
import { AuthorizationService } from '../../providers/plansee/authorization-service';
import { PUsersListPageWsDTO, PCustomerUserWsDTO, PUserWsDTO } from '../../providers/types/planseeoccaddon';
import { PUsersService } from '../../providers/plansee/p-users-service';
import { GlobalsService } from '../../providers/types/globals.service';
import { take } from 'rxjs/operators';
import { UserLocation } from '../../providers/types/ycommercewebservices';
import { PageRights } from '../../rights/page-rights';

export const P_MODULES_NAMES = {
  [PageRights.RFQS]: {
    name: 'menuPanel.rfqs',
    order: 1,
  },
  [PageRights.PRODUCTS]: {
    name: 'menuPanel.products',
    order: 3,
  },
  [PageRights.ORDERS]: {
    name: 'menuPanel.ordersInline',
    order: 4,
  },
  [PageRights.SHIPMENTS]: {
    name: 'menuPanel.shipments',
    order: 5,
  },
  [PageRights.FORECASTS]: {
    name: 'menuPanel.forecast',
    order: 6,
  },
  [PageRights.CONSIGNMENTS]: {
    name: 'menuPanel.consignments',
    order: 7,
  },
  [PageRights.INVOICES]: {
    name: 'menuPanel.invoices',
    order: 8,
  },
  [PageRights.CLAIMS]: {
    name: 'menuPanel.claims',
    order: 9,
  },
  [PageRights.DOCUMENTS]: {
    name: 'menuPanel.documents',
    order: 10,
  },
  [PageRights.HOT_ZONE_CONFIGURATOR]: {
    name: 'menuPanel.hotZoneConfiguratorInline',
    order: 2,
  }
};

@Component({
  selector: 'users-list',
  template: require('./users-list.component.html'),
  styles: [require('./users-list.component.scss')]
})


export class UsersListComponent extends PDataTable<PUsersListPageWsDTO> implements OnInit {
  moduleNames = P_MODULES_NAMES;
  sortedModuleNames = Object.entries(P_MODULES_NAMES)
    .sort((v1, v2) => v1[1].order - v2[1].order)
    .map(v => v[0]);

  defaultSort = 'creationTime-desc';

  get USERTYPE() {
    return this.globals.DEFAULT_USERTYPE;
  }

  @Output()
  onDeleteUser = new EventEmitter();

  @Output()
  onEditUser = new EventEmitter();

  items: PCustomerUserWsDTO[] = [];

  constructor(
    private usersService: PUsersService,
    private globals: GlobalsService,
    authorizationService: AuthorizationService,
    i18nService: TranslateService,
    loadingService: LoadingService) {
    super(i18nService, authorizationService, loadingService);
  }

  private currentUser: PUserWsDTO;
  private _locations: UserLocation[] = [];
  private diffCount = 0;

  ngOnInit() {
    super.ngOnInit();
    this.currentUser = this.authorizationService.getUserProfile();
    this._locations = this.globals.toUserLocationList(this.authorizationService.noWildcardLocations);
  }

  reloadItems(params: DataTableParams) {
    this.updateQuery(params);
    this.usersService.getSuperuserUsers(this._query)
      .pipe(take(1))
      .subscribe(data => {
        this.items = data.customers;
        // Update pagination with the counts comming from BE
        this.refreshPagination(data.pagination);
      });
  }

  deleteUser(user: any) {
    this.onDeleteUser.emit(this.convertToPCustomerUserWsDTO(user));
  }

  editUser(user: any) {
    this.onEditUser.emit(this.convertToPCustomerUserWsDTO(user));
  }

  isEditable(user: any) {
    // current logged in user who should not edit/delete himself
    if (this.authorizationService.isSuperUserMaster) {
      return (user.uid !== this.currentUser.uid && (user.userType !== this.USERTYPE.SUPERUSER_MASTER));
    } else {
      return (user.uid !== this.currentUser.uid && (user.userType === this.USERTYPE.REGULAR));
    }
  }

  getUserType(userType: string) {
    let _usertype = this.globals.DEFAULT_USERTYPES.filter(type => type.value === userType)[0];
    return _usertype ? this.i18nService.instant(_usertype.name) : '';
  }

  get adminLocations() {
    return this._locations.map(location => location.fullName);
  }

  getLocationsDiff(locations: UserLocation[]) {
    let diff = [];
    this.diffCount = diff.length;

    // exclude wildcard locations
    locations = locations.filter(location => (location.city && location.city !== '') && (location.countryName && location.countryName !== ''));

    if (this._locations.length > 0 && locations.length > 0) {
      const userLocations = locations.map(location => location.fullName);
      const adminLocations = this.adminLocations;

      userLocations.forEach(location => {
        if (adminLocations.indexOf(location) < 0) {
          diff.push(location);
        }
      });
    }
    this.diffCount = diff.length;
    return diff.length;
  }

  get DiffCount() {
    return this.diffCount;
  }

  private convertToPCustomerUserWsDTO(user): PCustomerUserWsDTO {
    return {
      uid: user.uid,
      creationDate: null,
      titleCode: user.titleCode,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      address: {phone: user.contactInfo.phone},
      function: user.function,
      modules: user.modules,
      locationList: user.locationList,
      userType: user.userType,
      superuser: user.superuser
    };
  }
}
