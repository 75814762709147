export const INVOICE_CATEGORY_FACET_CODE = 'invoiceCategory';

export enum InvoiceBaseQuery  {
  INVOICE = ':invoiceCategory:invoice',
  CREDIT_DEBIT_NOTES = ':invoiceCategory:credit_debit_notes',
  ADVANCED_PAYMENTS = ':invoiceCategory:advanced_payments'
}

export enum InvoiceCategoryCode {
  INVOICE = 'invoice',
  CREDIT_DEBIT_NOTES = 'credit_debit_notes',
  ADVANCED_PAYMENTS = 'advanced_payments'
}
